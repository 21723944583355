import React from 'react'

import { Head } from '../../components/Head'
import { BrowseJobsExample } from '../../examples/react/pages/BrowseJobsExample'

const Page = () => (
  <>
    <Head title="Selaa työpaikkoja" />
    <BrowseJobsExample />
  </>
)

export default Page
